import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DefaultEditor } from 'react-simple-wysiwyg';
import TextField from '@mui/material/TextField';
import styles from "../styles/styles.css";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import IconButton from '@mui/material/IconButton';
import swal from "sweetalert";
import api from "../configs/api.js";
import FileUpload from 'react-material-file-upload';
import CardMedia from "@mui/material/CardMedia";
import { AppContext } from "../ProviderContext";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  m: 1,
  width: '90%',
  overflow:'auto',
  height:'600px',
  maxWidth:'600px'
};

export default function Modal_edit_notice(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
 
  const [html, setHtml] = React.useState('meu <b>HTML</b>');
  const [title,setTitle] = React.useState();
  const [desc,setDesc] = React.useState();
  const [files, setFiles] = React.useState([]);
  const [data,setData] = React.useState(false);
  const [imgdt,setImgdt] = React.useState(false);
  const contextData = React.useContext(AppContext);

  function handleClose(){
    setOpen(false);
  };

  function onChange(e) {
    setHtml(e.target.value);
  }
 
  const handleSubmit = () =>{
    const not_id = props.load.id;
    const dataObj = {not_id,files,title,desc,html};
    
    api.post("/api/update_notice", dataObj, {
      headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
      }
    }).then(function (response, data) {
          data = response.data;
          if(data.result){
              swal("Atualizado!","Com Sucesso!");
              setOpen(false);
              contextData.setNotices(false);
          }else{
              swal("Erro!","X95!");
          }
    }).catch(function (error) {
      swal("Oops!", "erro!x310");
    });

  }
  React.useEffect(() => {
    if(props.load != false){
        if(props.load != data){
            setOpen(true);
            console.log(props.load);
            setTitle(props.load.title);
            setDesc(props.load.descri);
            setHtml(props.load.content_notice);
            setImgdt(props.load.img);
        }
    }
  },[props.load]);

  return (
    <div>
      {/* <IconButton  onClick={handleOpen} color="primary"> <AddAlertIcon /></IconButton > */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
            <Typography variant="h5" align="center" component="h2">
            Editar aviso!
            </Typography>
            <CardMedia
            component="img"
            height="auto"
            image={'https://masterplacemall.com.br/sistema/upload/'+imgdt}
            alt="=="
            />
            <FileUpload value={files} onChange={setFiles}  title='Alterar foto!'/>

            <TextField id="standard-basic" sx={{ m: 1, width: '100%' }} label="Titulo" value={title}
              onChange={(e) => setTitle(e.target.value)}  />
              <TextField id="standard-basic" sx={{ m: 1, width: '100%' }} label="Descrição breve"  value={desc}
              onChange={(e) => setDesc(e.target.value)}  />
            <DefaultEditor value={html} onChange={onChange} />
            <Button
              className="Btntheme"
              type="submit"
              fullWidth
              onClick={handleSubmit}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Atualizar
            </Button>
        </Box>
      </Modal>
    </div>
  );
}
