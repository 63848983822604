import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import history from "./history";

import Login from "./pages/Login-page";
import Client from "./pages/Client-page";
import Admin from "./pages/Admin";
import Admin_login from "./pages/Admin_login";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <div>
    <BrowserRouter history={history}>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/admin-login" element={<Admin_login />} />
            <Route exact={true} path="/administrador" element={<ProtectedRoute><Admin page='admin' /></ProtectedRoute>} />
            <Route exact={true} path="/users" element={<ProtectedRoute><Admin page='users' /></ProtectedRoute>} />
            <Route exact={true} path="/dashboard" element={<ProtectedRoute><Client /></ProtectedRoute>} />
        </Routes>
    </BrowserRouter>
  </div>
);