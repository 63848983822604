import React, { createContext, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import AppProvider from "./ProviderContext";

const AppContext = createContext();

export default function ProtectedRoute({ children }) {
  
  const [obj, setObj] = useState(0);
  const [token, setToken] = useState(0);

  if (sessionStorage.getItem("token")) {
    return <AppProvider>{children}</AppProvider>;
  }
  return <Navigate to="/" replace />;
};