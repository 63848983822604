import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../styles/styles.css";
import history from "../history.js";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import api from "../configs/api.js";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Master Place Mall
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login(props) {
  // const [user, setUser] = ProviderContext('');
  const navigate = useNavigate();
  const [login, setLogin] = React.useState("");
  const [senha, setSenha] = React.useState("");

  const handleSubmit = (event) => {
    // if (login == "admin" && senha == 1234) {
    //   navigate("/dashboard", { replace: true });
    // }

    const dataObj = { login, senha };
    api.post("/api/auth/user", dataObj, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(function (response, data) {
        data = response.data;

        if (data.token && data.loja) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("loja", data.loja);
          swal("Olá!", data.loja);
          // dispatch({
          //   type: "setUser",
          //   payload: data
          // });
          navigate("/dashboard", { replace: true });
        }
        data.erro ? swal("Oops!", "Login ou senha Inválidos !") : null;
      })
      .catch(function (error) {
        swal("Oops!", "Login ou senha Inválidos !");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img
            className="imglogin"
            src="https://masterplacemall.com.br/wp-content/uploads/2021/03/logotipo-masterplace-mall-1024x930.jpg"
          />

          <Box component="div" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="senha"
              label="Senha"
              type="password"
              id="senha"
              autoComplete="current-password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembrar"
            />
            <Button
              className="Btntheme"
              type="submit"
              fullWidth
              onClick={handleSubmit}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
