import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AppContext } from "../ProviderContext";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Modal_edit_notice from "../components/Modal_edit_notice";
import api from "../configs/api.js";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// import renderHTML from 'react-render-html';

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import swal from "sweetalert";
import View_notice from "../components/View_notice";


export default function Noticies() {
  const [content, setContent] = React.useState("none");
  const [objNotices,setObjNotices] = React.useState();
  const contextData = React.useContext(AppContext);
  const [idcard,setIdcard] = React.useState(false);
  const [openEditnotice,setOpenEditnotice] = React.useState(false);

  React.useEffect(() => {
    if(!contextData.notices){
        load_notices();
    }
  },[contextData.notices]);

  React.useEffect(() => {
    if(!objNotices){
      if(!contextData.notices){
         load_notices();
      }else{
        setObjNotices(contextData.notices);
      }
    }
  });

  function load_notices(){
    api.get("/api/get_notices", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
    }).then(function (response, data) {
        data = response.data;
        setObjNotices(data.result);
        contextData.setNotices(data.result);
    }).catch(function (error) {
       
    });
  } 

  function showContent(idc) {
    console.log(idc);
    setIdcard(idc);
    content == "none" ? setContent("block") : setContent("none");
    console.log(content);
  }

  function showcard(idc){
    return idcard == idc ? 'block':'none';
  }

  function load_edit(data){
    setOpenEditnotice(data);
  }
  function load_del(id,title){
    swal({
        title: "Tem certeza?",
        text: "Excluir! "+title,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
            api.get("/api/delete_notices/"+id, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + sessionStorage.getItem("token")
                }
            }).then(function (response, data) {
               
                load_notices();
                swal("deletado!", {
                    icon: "success",
                });
                
            }).catch(function (error) {
               
            });

          
        } else {
          swal("Cancelado!");
        }
      });
  }

  return (
    <div className="center-content">
        <Modal_edit_notice load={openEditnotice}/>
      {!objNotices? <div>sem dados!</div>: 
        objNotices.map((lista, index) => (
            
      <Card sx={{ maxWidth: 350 }} className="card-bt-custom" key={lista.id} >
        <CardActionArea onClick={(e) => showContent(lista.id)}>
          <CardMedia
            component="img"
            height="auto"
            image={'https://masterplacemall.com.br/sistema/upload/'+lista.img}
            alt="=="
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              <View_notice id_notice={lista.id} /> <DeleteForeverIcon color="warning" onClick={(e) => load_del(lista.id,lista.title)} /> <ModeEditIcon onClick={(e) => load_edit({"id":lista.id,"img":lista.img,"title":lista.title,"content_notice":lista.content_notice,"descri":lista.descri})} color="success" /> {lista.title}

            </Typography>
            <Typography variant="body2" color="text.secondary">
            {lista.descri}
            </Typography>
           
            <Box className="Full-Text-contente" style={{display:showcard(lista.id)}}>
            {/* {renderHTML(lista.content_notice)} */}
            <div dangerouslySetInnerHTML={{ __html:lista.content_notice}} />
            </Box>
            
          </CardContent>
        </CardActionArea>
      </Card>
      ))}
    </div>
  );
}
