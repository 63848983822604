import React, { useState, useEffect, useContext,useRef} from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DateTimePicker from "react-datetime-picker";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import api from "../configs/api.js";
import { AppContext } from "../ProviderContext";
import moment from 'moment';
import Button from '@mui/material/Button';
import Modal_edit_pay from "../components/Modal_edit_pay";
import FileCopyIcon from '@mui/icons-material/FileCopy';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { TableToExcelReact } from "table-to-excel-react";

export default function DataTable_pay(props) {
  const [datein, setDatein] = useState(new Date());
  const [editModal, setEditModal] = React.useState(false);
  const [objlista, setObjlista] = useState(false);
  const [dateend, setDateend] = useState(new Date());
  const contextData = React.useContext(AppContext);
  const [total,setTotal] = useState(0);
  const [objUsers,setObjUsers] = useState(false);
  const [userSelect, setUserSelect] = React.useState('');
  const tableRef = useRef(null);
  
  React.useEffect(() => {
    if(objlista){
      var ctd = 0;
      objlista.map((v,index) =>{
        ctd = parseFloat(ctd)+parseFloat(v.value_transaction)
      });
      setTotal(ctd);
    }
  },[objlista])

  function load_loja_nome(id){
    var r;
    objUsers.map((v,index) =>{
      if(v.id === id){
       r = v.login;
      }
    });
    return r;
  }

  React.useEffect(() => {
    if(!contextData.objUsers){
      const dataObj = {datein};
      api.post("/api/get_users", dataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      })
      .then(function (response, data) {
        data = response.data;
        contextData.setObjUsers(data.result);
        setObjUsers(data.result);
      })
      .catch(function (error) {
       
      });
    }else{
      setObjUsers(contextData.objUsers);
    }
  });

  React.useEffect(() => {
    if(objlista == false){
      setObjlista(props.paydata);
     }
     if(contextData.objpay != false){
      setObjlista(contextData.objpay);
     }
  },[contextData.objpay]);

  function format_brl(v){
    return parseFloat(v).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  const handleSubmit = (event) => {
    const dataObj = {datein,dateend,userSelect};
    api.post("/api/transactions_filter", dataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }
    })
    .then(function (response, data) {
      data = response.data;
      setObjlista(data.result);
    })
    .catch(function (error) {
     
    });
  };
  

  const handleChange = (event) => {
    setUserSelect(event.target.value);
  };

  return (
    <div>
      <Modal_edit_pay editModal={editModal}/>
     

      <div style={{ display: props.filter }}>
        <div className="box-search">
          
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Selecione</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userSelect}
                label="Selecione a loja"
                onChange={handleChange}
              >
                <MenuItem value={"all"}>Todas Lojas</MenuItem>
                {!objUsers? null:
                objUsers.map((option) => (
                      <MenuItem value={option.id}>{option.login}</MenuItem>
                ))
                }
                
              </Select>
            </FormControl>
          </Box>
          <DateTimePicker
            className="dia-lancamento"
            onChange={setDatein}
            value={datein}
            disableClock={true}
          />
          <div>Até</div>
          <DateTimePicker
            className="dia-lancamento"
            onChange={setDateend}
            value={dateend}
            disableClock={true}
          />
          <div>
            <IconButton
              className="search-btn"
              aria-label="delete"
              color="primary"
              onClick={handleSubmit}
            >
              <ManageSearchIcon />
            </IconButton>
          </div>
        </div>
      </div>
      
      <div  className="max-h-table">
      <table className="Table-pay" id="table-to-xls">
        <thead>
          <tr>
          <th>#ID</th>
            <th>Data</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {!objlista? <div>sem dados!</div>: 
        objlista.map((lista, index) => (
          <tr >
            <td key={lista.id}>{lista.id}</td>
              <td>{moment(lista.date_value_transaction).format('DD/MM/YYYY')}</td>
              <td>{format_brl(lista.value_transaction)}</td>
              <td className="center-content">
              {load_loja_nome(lista.id_user)}
              </td>
            </tr>
        ))
        }
       </tbody>
      </table>
      </div>
    <>Total: {format_brl(total)}</>
    <TableToExcelReact table="table-to-xls" fileName="myFile" sheet="sheet 1">
        <IconButton><FileCopyIcon/></IconButton>
    </TableToExcelReact>
    </div>
  );
}
