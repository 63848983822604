import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import DateTimePicker from "react-datetime-picker";
import AutorenewIcon from '@mui/icons-material/Autorenew';

import IntlCurrencyInput from "react-intl-currency-input";
import swal from "sweetalert";
import api from "../configs/api.js";
import { AppContext } from "../ProviderContext";


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
};

export default function Pay_edit_day(props) {
  const [datein, setDatein] = useState(new Date());
  const [valuepay, setValuepay] = React.useState();
  const contextData = React.useContext(AppContext);

  React.useEffect(() => {
    if(contextData.editpay){     
        setDatein(new Date(contextData.editpay.dateinc));
        var vrtemp = parseFloat(props.objEdit.vlr).toFixed(2);
        setValuepay(vrtemp);
    }
  },[contextData.editpay]);

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();
    setValuepay(value);
    
  };

  function handleSubmit() {
    const id_t = props.objEdit.id;
    const dataObj = { datein, valuepay ,id_t};
    new api.post("/api/transactions_edit", dataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }
    })
      .then(function (response, data) {
        data = response.data;
          console.log(data);
          props.handleClose();
          contextData.setObjpay(false);
          swal("Adicionado com Sucesso!");
      })
      .catch(function (error) {
        console.log(data);
      });
  }
  return (
    <Box
      component="div"
      className="lancamanto-box"
      noValidate
      autoComplete="off"
    >
      <div>
        <IntlCurrencyInput
          className="valor-dia"
          currency="BRL"
          config={currencyConfig}
          onChange={handleChange}
          value={valuepay}
          autoReset={true}
        />
      </div>
      <DateTimePicker
        className="dia-lancamento"
        onChange={setDatein}
        value={datein}
        disableClock={false}
      />
      <div>
        <Button
          className="Btntheme"
          type="button"
          fullWidth
          onClick={handleSubmit}
          variant="contained"
          startIcon={<AutorenewIcon />}
        >
          Atualizar
        </Button>
      </div>
    </Box>
  );
}
