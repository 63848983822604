import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Toolbar from "@mui/material/Toolbar";

import Noticies from "../components/Noticies";
import Pay_table from "../components/Pay_table_admin";

import Grid from "@mui/material/Grid";

import FilterListIcon from "@mui/icons-material/FilterList";
import CampaignIcon from "@mui/icons-material/Campaign";
const drawerWidth = 240;
import DataTable_users from "../components/DataTable_users";

export default function Admin_users(props) {
   
    return (
        <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` }
            }}
          >
          <Toolbar />
          <Box className="main-custom" sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12} md={12} sm={12}>
                <div className="f-r-items">
               
                </div>
                <div className="divisor" />
                <DataTable_users/>  
              </Grid>
            </Grid>
          </Box>
        </Box>
    );
  
}
