import React, { createContext, useState, useContext } from "react";
import { Navigate } from "react-router-dom";

export const AppContext = createContext();

export default function AppProvider({ children }) {
  const [objpay, setObjpay] = useState(0);
  const [editpay, setEditpay] = useState(0);
  const [edituser,setEdituser] = useState(false);
  const [notices,setNotices] = useState(false);
  const [objUsers,setObjUsers] = useState(false);
  const token_temp = sessionStorage.getItem("token");
  return !token_temp ? (
    <Navigate to="/" replace />
  ) : (
    <AppContext.Provider value={{ objpay, setObjpay,editpay, setEditpay,edituser,setEdituser,objUsers,setObjUsers,notices,setNotices}}>
      {children}
    </AppContext.Provider>
  );
}
