import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Noticies_admin from "../components/Noticies_admin";
import Pay_table from "../components/Pay_table_admin";
import Modal_add_pay from "../components/Modal_add_pay";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import Grid from "@mui/material/Grid";

import FilterListIcon from "@mui/icons-material/FilterList";
import CampaignIcon from "@mui/icons-material/Campaign";
import api from "../configs/api.js";
import { AppContext } from "../ProviderContext";

import Modal_add_notice from "../components/Modal_add_notice";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link } from "react-router-dom";
const drawerWidth = 240;

export default function Admin_dashboard(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [filter, setfilter] = React.useState("none");
    const [paydata,setPaydata] = React.useState(false);
    // const contextData = React.useContext(AppContext);
   
  
    function showFilter() {
      filter == "none" ? setfilter("block") : setfilter("none");
    }
    return (
        <Box
            component="main"
            sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` }
            }}
        >
        <Toolbar />
        <Box className="main-custom" sx={{ flexGrow: 1 }}>
            <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            >
            <Grid item xs={8} md={8} sm={12}>
                <div className="f-r-items">
                
                {/* <Modal_add_pay /> */}
                <Button
                    variant="outlined"
                    className="btn-f-m-l"
                    startIcon={<FilterListIcon />}
                    onClick={showFilter}
                >
                    Filtrar
                </Button>
                </div>

                <div className="divisor" />
                <Pay_table filter={filter} paydata={paydata} />
            </Grid>
            <Grid item xs={4} md={4} sm={12}>
                <h3 className="h3-Noticies">
                <CampaignIcon /> Avisos <Modal_add_notice/>
                </h3>
                <Noticies_admin />
            </Grid>
            </Grid>
        </Box>
        </Box>
    );
  
}
