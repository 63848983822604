import React, { useState, useEffect, useContext,useRef} from "react";
import api from "../configs/api.js";
import Button from '@mui/material/Button';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { AppContext } from "../ProviderContext";
import Modal_edit_login_user from "../components/Modal_edit_login_user";
import TextField from '@mui/material/TextField';
import Modal_add_login_user from "../components/Modal_add_login_user";

export default function DataTable_users(props) {
    const [listUsers,setListUsers] = useState(false);
    const contextData = React.useContext(AppContext);
    const [search,setSearch] = useState('');

    React.useEffect(() => {
        if(!contextData.objUsers){
            load_users();
        }
        if(!listUsers){
          setListUsers(contextData.objUsers);
        }
    });
    React.useEffect(() => {
        if(!contextData.objUsers){
            load_users();
        }
    },[contextData.objUsers]);
    
    function load_users(){
        api.get("/api/get_users", {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + sessionStorage.getItem("token")
            }
        }).then(function (response, data) {
            data = response.data;
            contextData.setObjUsers(data.result);
            setListUsers(data.result);
        }).catch(function (error) {
           
        });
    }
    
    return (
        <><Modal_edit_login_user/>
        <div className="ac-users">
        <TextField id="standard-basic" label="Pesquisar" variant="standard" value={search}
              onChange={(e) => setSearch(e.target.value)}  />
        <Modal_add_login_user />
        </div>
        <table className="Table-pay" id="table-to-xls">
        <thead>
          <tr>
            <th>ID</th>
            <th>Login</th>
            <th>último acesso</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
        {!listUsers? <div>sem dados!</div>: listUsers.filter(person => person.login.includes(search)).map(filtered => (
          <tr key={filtered.id}>
              <td>{filtered.id}</td>
              <td>{filtered.login}</td>
              <td>{filtered.login_date}</td>
              <td className="center-content">
                <Button variant="text" onClick={() => {
                  contextData.setEdituser({"active":filtered.active,"id":filtered.id,"login":filtered.login,"password":filtered.password});
                }}><ModeEditIcon /></Button>
              </td>
            </tr>
        ))}
       </tbody>
      </table></>
    )
}