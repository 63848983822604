import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Noticies from "../components/Noticies";
import Pay_table from "../components/Pay_table";
import Modal_add_pay from "../components/Modal_add_pay";

import Grid from "@mui/material/Grid";

import FilterListIcon from "@mui/icons-material/FilterList";
import CampaignIcon from "@mui/icons-material/Campaign";
import api from "../configs/api.js";
import { AppContext } from "../ProviderContext";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link } from "react-router-dom";
const drawerWidth = 240;


export default function Cliente_page(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [filter, setfilter] = React.useState("none");
  const [paydata,setPaydata] = React.useState(false);
  const contextData = React.useContext(AppContext);
  

  function showFilter() {
    filter == "none" ? setfilter("block") : setfilter("none");
  }
  React.useEffect(() => {
    // se for false ele busca na api
    // Pay_of_day que seta false apos pagamento
    if(contextData.objpay){}else{
      api.post("/api/transactions", {}, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      })
      .then(function (response, data) {
        data = response.data;
        data.result?setPaydata(data.result): null;
        contextData.setObjpay(data.result);
        
      })
      .catch(function (error){});
    }
    
  });
  
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen); 
  };

  const drawer = (
    <div>
        <Toolbar className="d-none" />

        <img
          className="logoDashboard"
          src="https://www.ibgl.com.br/franquias/2/15738/editor-html/426259.png"
        />

        <List>
          <ListItem key={"User"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={sessionStorage.getItem("loja")} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"Dashboard"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"Sair"} disablePadding>
            <Link to="/">
              <ListItemButton>
                <ListItemIcon>
                  <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText primary={"Sair"} />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
    </div>
  );
  
  const container = window !== undefined ? () => window().document.body : undefined;
  
    
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div"></Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` }
          }}
        >
          <Toolbar />
          <Box className="main-custom" sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={8} md={8} sm={12}>
                <div class="f-r-items">
                  
                  <Modal_add_pay />
                  <Button
                    variant="outlined"
                    className="btn-f-m-l"
                    startIcon={<FilterListIcon />}
                    onClick={showFilter}
                  >
                    Filtrar
                  </Button>
                </div>

                <div className="divisor" />
                <Pay_table filter={filter} paydata={paydata} />
              </Grid>
              <Grid item xs={4} md={4} sm={12}>
                <h3 className="h3-Noticies">
                  <CampaignIcon /> Avisos
                </h3>
                <Noticies />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  
}
