import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AppContext } from "../ProviderContext";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import api from "../configs/api.js";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// import renderHTML from 'react-render-html';


export default function Noticies() {
  const [content, setContent] = React.useState("none");
  const [objNotices,setObjNotices] = React.useState();
  const contextData = React.useContext(AppContext);
  const [idcard,setIdcard] = React.useState();

  React.useEffect(() => {
  if(!contextData.notices){
        load_notices();
    }
  },[contextData.notices]);

  React.useEffect(() => {
    if(!objNotices){
      load_notices();
    }
  });
  setInterval(function() {
    load_notices();
  }, 200000);

  function load_view_notice(id){
    api.get("/api/notices_view/"+id, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }
    }).then(function (response, data) {
      
    }).catch(function (error) {
      
    });
  }
  function load_notices(){
    api.get("/api/get_notices", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
    }).then(function (response, data) {
        data = response.data;
        setObjNotices(data.result);
        contextData.setNotices(data.result);
        data.result.map((lista, index) => (
          load_view_notice(lista.id)
        ))
    }).catch(function (error) {
       
    });
    
  } 

  function showContent(idc) {
    console.log(idc);
    setIdcard(idc);
    content == "none" ? setContent("block") : setContent("none");
    console.log(content);
  }
  function showcard(idc){
    return idcard == idc ? 'block':'none';
  }

  return (
    <div className="center-content">
      {!objNotices? <div>sem avisos!</div>: 
        objNotices.map((lista, index) => (
      <Card sx={{ maxWidth: 350 }} className="card-bt-custom" key={lista.id} >
        <CardActionArea onClick={(e) => showContent(lista.id)}>
          <CardMedia
            component="img"
            height="auto"
            image={'https://masterplacemall.com.br/sistema/upload/'+lista.img}
            alt="=="
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              <DoneAllIcon color="success" /> {lista.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            {lista.descri}
            </Typography>
           
            <Box className="Full-Text-contente" style={{display:showcard(lista.id)}}>
            {/* {renderHTML(lista.content_notice)} */}
            <div dangerouslySetInnerHTML={{ __html:lista.content_notice}} />
            </Box>
            
          </CardContent>
        </CardActionArea>
      </Card>
      ))}
    </div>
  );
}
