import React, { useState, useEffect, useContext } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DateTimePicker from "react-datetime-picker";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import api from "../configs/api.js";
import { AppContext } from "../ProviderContext";
import moment from 'moment';
import Button from '@mui/material/Button';
import Modal_edit_pay from "../components/Modal_edit_pay";

export default function DataTable_pay(props) {
  const [datein, setDatein] = useState(new Date());
  const [editModal, setEditModal] = React.useState(false);
  const [objlista, setObjlista] = useState(false);
  const [dateend, setDateend] = useState(new Date());
  const contextData = React.useContext(AppContext);
  const [total,setTotal] = useState(0);
  
  React.useEffect(() => {
    if(objlista){
      var ctd = 0;
      objlista.map((v,index) =>{
        ctd = parseFloat(ctd)+parseFloat(v.value_transaction)
      });
      setTotal(ctd);
    }
  },[objlista])

  React.useEffect(() => {
    if(objlista == false){
      setObjlista(props.paydata);
     }
     if(contextData.objpay != false){
      setObjlista(contextData.objpay);
     }
  },[contextData.objpay]);

  function format_brl(v){
    return parseFloat(v).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  const handleSubmit = (event) => {
    const dataObj = { datein, dateend };
    
    api.post("/api/transactions_filter", dataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      })
      .then(function (response, data) {
        data = response.data;
        console.log(data);
        setObjlista(data.result);
      })
      .catch(function (error) {
       
      });
  };
 
  return (
    <div>
      <Modal_edit_pay editModal={editModal}/>
      <div style={{ display: props.filter }}>
        <div className="box-search">
          <DateTimePicker
            className="dia-lancamento"
            onChange={setDatein}
            value={datein}
            disableClock={true}
          />
          <div>Até</div>
          <DateTimePicker
            className="dia-lancamento"
            onChange={setDateend}
            value={dateend}
            disableClock={true}
          />
          <div>
            <IconButton
              className="search-btn"
              aria-label="delete"
              color="primary"
              onClick={handleSubmit}
            >
              <ManageSearchIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div  className="max-h-table">
      <table className="Table-pay">
        <thead>
          <tr>
            <th>Data</th>
            <th>Valor</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
        {!objlista? <div>sem dados!</div>: 
        objlista.map((lista, index) => (
          <tr key={lista.id}>
              <td>{moment(lista.date_value_transaction).format('DD/MM/YYYY')}</td>
              <td>{format_brl(lista.value_transaction)}</td>
              <td className="center-content">
                <Button variant="text" onClick={() => {
                  contextData.setEditpay({"id":lista.id,"dateinc":lista.date_value_transaction,"vlr":lista.value_transaction});
                }}><ModeEditIcon /></Button>
              </td>
            </tr>
        ))
        }
       </tbody>
      </table>
      </div>
    <>Total: {format_brl(total)}</>
    </div>
  );
}
