import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { AppContext } from "../ProviderContext";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveIcon from "@mui/icons-material/Save";
import swal from "sweetalert";
import api from "../configs/api.js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };


export default function Modal_edit_login_user(props) {
  const [open, setOpen] = React.useState(false);
  const contextData = React.useContext(AppContext); 
  const [id_user,setId_user] = React.useState();
  const [uctive,setUctive] = React.useState(false);
  const [loja,setLoja] = React.useState();
  const [password,setPassword] = React.useState();
 

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    contextData.setEdituser(false);
    setOpen(false);
  };
  
  function load_users(){
    api.get("/api/get_users", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      })
      .then(function (response, data) {
        data = response.data;
        setObjUsers(data.result);
      })
      .catch(function (error) {
       
      });
  }
  
  React.useEffect(() => {
    if(contextData.edituser){
        setOpen(true);
        setUctive(contextData.edituser.active == 'true'? true:false);
        setLoja(contextData.edituser.login);
        setPassword(contextData.edituser.password);
        setId_user(contextData.edituser.id);
        
        // setObjEdr(contextData.editpay); 
    }
  },[contextData.edituser]);

  const handleChange = (event) => {
    setUctive(event.target.checked);
  };

  const handleSubmit = () =>{
      const dataObj = {id_user,uctive,loja,password };
      api.post("/api/update_user", dataObj, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      }).then(function (response, data) {
            data = response.data;
            if(data.result){
                setOpen(false);
                contextData.setEdituser(false);
                swal("Atualizado!","Com Sucesso!");
            }else{
                swal("Erro!","Ao atualizar dados!");
            }
           
      }).catch(function (error) {
        swal("Oops!", "Login ou senha Inválidos !x35");
      });

  }

  return (
    <div>
      
      <BootstrapDialog
        className="modal-m-h"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Editar {contextData.edituser.login}
        </BootstrapDialogTitle>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { m: 1, width: '50%' },
        }}
        noValidate
        autoComplete="off"
        className='d-fle'
        >
            <TextField id="outlined-basic" onChange={(e) => setLoja(e.target.value)} value={loja} label="Loja" variant="outlined" />
            <TextField id="outlined-basic" onChange={(e) => setPassword(e.target.value)} value={password} label="Senha" variant="outlined" />
            <FormControlLabel onChange={handleChange} control={<Switch  checked={uctive} />} label="Ativar" />
            <Button
            className="Btntheme"
            type="button"
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            startIcon={<SaveIcon />}
            >
            Atualizar
            </Button>
        </Box>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
