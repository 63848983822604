import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import DateTimePicker from "react-datetime-picker";
import SaveIcon from "@mui/icons-material/Save";

import IntlCurrencyInput from "react-intl-currency-input";
import swal from "sweetalert";
import api from "../configs/api.js";
import { AppContext } from "../ProviderContext";


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
};

export default function Pay_of_day(props) {
  const [datein, setDatein] = useState(new Date());
  const [valuepay, setValuepay] = React.useState();
  const contextData = React.useContext(AppContext);

  // const contextData = useContext(AppContext);
  // contextData.setToken = 'asmd9iamd19imd1';
  // console.log(contextData);

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();
    setValuepay(value);
  };

  function handleSubmit() {
    const dataObj = { datein, valuepay };
    new api.post("/api/transactions", dataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }
    })
      .then(function (response, data) {
        data = response.data;
        console.log(data);
          props.handleClose();
          contextData.setObjpay(false);
          swal("Adicionado com Sucesso!");
      })
      .catch(function (error) {
        // swal("Oops!", "Login ou senha Inválidos !");
      });
  }
  return (
    <Box
      component="div"
      className="lancamanto-box"
      noValidate
      autoComplete="off"
    >
      <div>
        <IntlCurrencyInput
          className="valor-dia"
          currency="BRL"
          config={currencyConfig}
          onChange={handleChange}
        />
      </div>
      <DateTimePicker
        className="dia-lancamento"
        onChange={setDatein}
        value={datein}
        disableClock={true}
      />
      <div>
        <Button
          className="Btntheme"
          type="button"
          fullWidth
          onClick={handleSubmit}
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Salvar
        </Button>
      </div>
    </Box>
  );
}
