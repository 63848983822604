import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import api from "../configs/api.js";

import { AppContext } from "../ProviderContext";
import VisibilityIcon from '@mui/icons-material/Visibility';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  m: 1,
  width: '90%',
  overflow:'auto',
  height:'600px',
  maxWidth:'600px'
};

export default function view_notice(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [listView ,setListView] = React.useState(false);

  function handleClose(){
    setOpen(false);
  };

  function load_view_notice(id){
    setOpen(true);
    api.get("/api/notices_view/"+id, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }
    }).then(function (response, data) {
        data = response.data;
        setListView(data.result);
    }).catch(function (error) {
      
    });
  }
  
  return (
    <>
      <VisibilityIcon onClick={(e) => load_view_notice(props.id_notice)} color="warning"/> 
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
            <Typography variant="h5" align="center" component="h2">
            Visualizações
            </Typography>
            {!listView? <div>sem dados!</div>: 
            listView.map((lista, index) => (
                <> 
                <Chip label={lista.user_login+" - "+lista.creat_date}/>
                </>
            ))}
        </Box>
      </Modal>
    </>
  );
}
